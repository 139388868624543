<template>
  <div class="maxBox">
    <div class="content">
      <div class="tit">
        <img src="../assets/img/icontfont2.png" />
        <span>咨询（收费）</span>
      </div>
      <ul>
        <li>
          <span @click="goPayment">乒乓球的搓球技术</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">台球的起源与发展</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">中国台球协会黑八比赛规则与分类</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">乒乓球的项目起源与项目变革</span>
          <span @click="goPayment"> 付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">少年与乒乓球</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">台球杆法讲解</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">乒乓球系统训练</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">诺斯克和中式8球</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">乒乓球基本知识普及</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">乒乓球高级对战技巧</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">台球手姿、站姿</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">乒乓球发球要领</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">台球进阶技巧</span>
          <span @click="goPayment">付费浏览</span>
        </li>
        <li>
          <span @click="goPayment">乒乓球旋球技巧</span>
          <span @click="goPayment">付费浏览</span>
        </li>
      </ul>

      <!-- <button v-if="!isBuy" class="but" @click="buyCar">
        友动台球年卡购买
      </button> -->
      <!-- <div class="qrcodeBox" v-else>
        <vue-qr :text="qrUrl" :size="200"></vue-qr>
        <h3>微信支付</h3>
      </div> -->
    </div>
  </div>
</template>

<script>
// import vueQr from "vue-qr";
export default {
  components: {
    // vueQr,
  },
  data() {
    return {
      qrUrl: "",
      isBuy: false,
    };
  },
  methods: {
    giveTicket() {
      this.$http
        .post("goodsNativePay", {
          goodsId: "100",
          goodsDescription: "乒乓球的搓球技术",
          amount: 1000,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.qrUrl = res.url;
          }
        });
    },
    buyCar() {
      this.isBuy = true;
    },

    goPayment() {
      this.$router.push("/payment");
    },
  },
  created() {
    this.giveTicket();
  },
};
</script>

<style lang="less" scoped>
.maxBox {
  height: calc(100vh - 100px);
  padding: 100px;
}
.content {
  width: 1200px;
  // border: 1px solid #000;
  padding: 20px;
  .tit {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid #999;
    img {
      margin-right: 10px;
    }
  }
  ul > li {
    padding: 10px 0;
    font-family: Microsoft YaHei;
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
    }
  }
}
</style>
